import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BgAnimation from '../components/backgroundAnimation/backgroundAnimation';
import { Layout, Hero, About, Jobs, Featured, Projects, Contact } from '@components';

// Add your styles for the quote section
const QuoteSection = styled.section`
  text-align: center;
  padding: 50px;
  font-size: 1.25em;
  color: #ccd6f6
  background-color: #0a192f;
`;

// Define the base style for both
const StyledMainContainerBase = styled.main`
  counter-reset: section;
`;

// Extend the base style for desktop version
const StyledMainContainerDesktop = styled(StyledMainContainerBase)`
  position: relative;

`;

const BgAnimationWrapper = styled.div`
  position: absolute;
  transform: translate(+230%,+110%) scale(2);
  z-index: -1;
`;

// Style for HeroWrapper only used in Desktop version
const HeroWrapper = styled.div`
  position: static;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  & > * {
    transform: scale(1);
  }
`;

const IndexPage = ({ location }) => {
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    // Only run this effect client-side
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 992);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);
 
  
  // Choose which container to render based on the `isMobile` state
  const StyledMainContainer = isMobile ? StyledMainContainerBase : StyledMainContainerDesktop;
  return (
    <Layout location={location}>
      <StyledMainContainer className="fillHeight">
        {/* Render BgAnimation and HeroWrapper only when not on mobile */}
        {!isMobile && 
        <BgAnimationWrapper>
          <BgAnimation />
        </BgAnimationWrapper>}
        {!isMobile ? (
          <HeroWrapper>
            <Hero />
          </HeroWrapper>
        ) : (
          <Hero />
        )}
        <About />
        <Jobs />
        <Featured />
        <Projects />
        <Contact />
        <QuoteSection>
          "Work hard, be kind, and amazing things will happen." - Conan O'Brien.
        </QuoteSection>
      </StyledMainContainer>
    </Layout>
  );
};
IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};
export default IndexPage;